<template>
    <div>
        <div class="vx-col w-full mb-base">
          <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
            <feather-icon icon="AwardIcon" class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow" svgClasses="h-8 w-8"></feather-icon>
            <h1 class="mb-6 text-white">Hello {{ $store.getters.userInfo.first_name }},</h1>
            <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">Welcome to Legully!</p>
          </vx-card>
        </div>

        <vs-row class="mb-base">
            <vs-col class="flex" vs-justify="center" vs-align="center">
                <router-link :to="{name: 'entity-add'}">
                    <vs-button class="mx-2" size="small" type="border">
                        Add New Entity
                    </vs-button>
                </router-link>
                <router-link :to="{name: 'entity-list'}">
                    <vs-button class="mx-2" size="small" type="border">
                        View My Entities
                    </vs-button>
                </router-link>
                <router-link :to="{name: 'compliancecalendar-user-home'}">
                    <vs-button class="mx-2" size="small" type="border">
                        Compliance Calendar
                    </vs-button>
                </router-link>
            </vs-col>
        </vs-row>

        <vs-row>
          <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-2">
            <h6 class="mb-2">Entities</h6>
            <vx-card>
              <Entities
                slot="no-body"
                hideFilters
                viewMode="compact"
                disablePagination
                :embedded_filters="{limit: 10}"
              />
              <div class="text-right" slot="no-body">
                <router-link :to="{name: 'entity-list'}">
                  <vs-button type="flat">
                    View All
                  </vs-button>
                </router-link>
              </div>
            </vx-card>
          </vs-col>

          <vs-col vs-xs="12" vs-sm="6" vs-lg="6" class="mb-2">
            <h6 class="mb-2">Compliances</h6>
            <vx-card>
              <Calendar
                slot="no-body"
                hideFilters
                viewMode="compact"
                disablePagination
                :embedded_filters="{limit: 5}"
              />
              <div class="text-right" slot="no-body">
                <router-link :to="{name: 'compliancecalendar-user'}">
                  <vs-button type="flat">
                    View All
                  </vs-button>
                </router-link>
              </div>
            </vx-card>
          </vs-col>
        </vs-row>
    </div>
</template>

<script>

import Calendar from "./organisation/Compliance/Calendar.vue"
import Entities from "./organisation/Entity/List.vue"

// import { requestPermission, messaging } from "../utils/fcm"

export default {
  components: {
    Calendar,
    Entities
  },
  data() {
    return {

    }
  },
  mounted() {
    // if (Notification.permission != 'granted') {
    //   this.showNotificationPopup()
    // } else {
    //   requestPermission()
    // }
  },
  methods: {
    // showNotificationPopup() {
    //   this.$vs.dialog({
    //     type: 'confirm',
    //     color: 'primary',
    //     title: `Subscribe to Notifications`,
    //     text: 'We need your permissions to send notifications for various things like Compliances Due, Upcoming Compliance and Compliances Marked, etc. Please click allow here and then click allow in your browser to grant permissions!',
    //     accept: () => this._requestNotificationPermissions(),
    //     cancel: () => null,
    //     acceptText: "Allow",
    //     cancelText: "Cancel"
    //   })
    // },
    // _requestNotificationPermissions() {
    //   if (Notification.permission != 'granted') {
    //     requestPermission()
    //   }
    // }
  }
}

</script>
